import { useEffect, useState } from "react";
import { getRequest, postRequest } from "../../utils/db";
import { Box, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import { bodyPadding } from "../../assets/global";
import { FormattedMessage } from "react-intl";
import { ItemProps } from "../../data/types";
import ItemCardContainer from "../ShoppingItems/ItemCardContainer";
import ItemCard from "../ShoppingItems/ItemCard";

const PopularProducts = () => {
  const [products, setProducts] = useState([]);

  const fetchData = async () => {
    const data = await getRequest("/api/products/getFavourite");
    console.log(data, "fav");
    if (!data.error) {
      setProducts(data);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box px={bodyPadding}>
      <Stack>
        <Text fontSize={25} fontWeight={"semibold"}>
          <FormattedMessage id="popularProducts" />
        </Text>
        <SimpleGrid columns={{ sm: 1, md: 2, xl: 3 }} spacing={6}>
          {/* {products.map(
            (product: ItemProps, index: number) =>
              index < 6 && (
                <ItemCardContainer key={index}>
                  <ItemCard item={product} />
                </ItemCardContainer>
              )
          )} */}
          {products.map((product: ItemProps, index: number) => (
            <ItemCardContainer key={index}>
              <ItemCard item={product} />
            </ItemCardContainer>
          ))}
        </SimpleGrid>
      </Stack>
    </Box>
  );
};

export default PopularProducts;
