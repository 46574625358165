import { locales } from "../locales";

const fr = {
  [locales.french]: {
    hello: "HOLA",
    appTitle: "Entre Murs Galerie",
    LocalSwitcherTitle: "Les meilleures offres du marché",
    france: "Français",
    arabic: "Arabe",
    copyrights: "Copyright",
    followUs: "Suivez-nous sur",
    forCustomers: "Pour les consommateurs",
    Payments: "Paiements",
    Shipping: "Expédition",
    productReturns: "Retours de produits",
    faq: "FAQ",
    paymentAtCheckout: "Paiement en caisse",
    MeetWithUs: "Faites notre connaissance",
    about: "À propos de",
    blog: "Blog",
    helpCenter: "Help Center",
    ourValue: "Notre valeur",
    SearchInput: "Rechercher des produits",
    drawerTitle: "Chariot De Courses",
    emptyCartText: "Le panier est vide,",
    emptyCartLink:
      "commencez dès maintenant votre meilleure expérience de shopping.",
    currency: "MAD",
    remove: "Retirer",
    removeItemConfirm: "Confirmation pour supprimer l'élément",
    removeItemConfMsg:
      "Êtes-vous sûr de vouloir supprimer cet article du panier d'achat ?",
    yes: "Oui",
    no: "Non",
    removeAllItems: "Vider le panier",
    removeAllConf:
      "Êtes-vous sûr de vouloir supprimer tous les articles du panier?",
    execute: "Exécuter l'ordre d'achat",
    signInButton: "Se connecter",
    signInPassword: "Mot de passe",
    email: "Email",
    forgotPassword: "Mot de passe oublié?",
    forgotPasswordLink: "Réinitialisez-le",
    dontHaveAcc: "Vous n'avez pas de compte?",
    registerLink: "S'inscrire",
    signInError:
      "Invalide email ou mot de passe, veuillez vérifier vos identifiants et réessayer.",
    menuCategores: "Les catégories",
    allCategories: "Toutes catégories",
    menuMainPage: "Page d'acceuil",
    menuMoreCategories: "Plus de catégories",
    menuAccount: "Compte",
    menu4Item1: "Se connecter",
    menu4Item2: "S'inscrire",
    menu4Item3: "Mot de passe oublié",
    menu4Item4: "Mon compte",
    menu4Item4Nested1: "Commandes",
    menu4Item4Nested2: "Paramètres",
    menu4Item4Nested3: "Adresse",
    menu4Item4Nested4: "Mode de paiement",
    menu4Item4Nested5: "Notification",
    shoppingCart: "Chariot De Courses",
    signOut: "Se déconnecter",
    menuHelp: "Centre d'aide",
    signInPage: "Connectez-vous à la plateforme",
    signInDesc: "Entrez votre e-mail pour commencer.",
    username: "Nom d'utilisateur",
    alreadyHaveAcc: "Vous avez déjà de compte?",
    signInLink: "Se connecter",
    signUpPage: "Commencez vos achats",
    signUpDescription: "Entrez votre e-mail pour commencer.",
    firstName: "Prénom",
    lastName: "Nom",
    phone: "Numéro de téléphone",
    address: "Adresse",
    city: "Ville",
    postCode: "Code postal",
    password: "Entrez le mot de passe",
    signUpButton: "S'inscrire",
    termsAndConditionText: "By continuing, you agree to our",
    termsOfServicesLink: "Terms of Service",
    privacyPolicy: "Privacy Policy",
    signUpError:
      "Le nom d'utilisateur existe déjà, veuillez saisir un autre nom d'utilisateur.",
    signUpDone: "Inscription terminée, bienvenue à Entre Murs Galerie.",
    resetPasswordPage: "Mot de passe oublié?",
    resetPasswordDescription:
      "Veuillez saisir l'adresse e-mail associée à votre compte et nous vous enverrons un lien par e-mail pour réinitialiser votre mot de passe.",
    resetPasswordButton: "Réinitialisation du mot de passe",
    goBack: "Retour",
    helpCenterTitle: "Centre d'aide :",
    helpCenterDescription: "NOUS SOMMES LÀ POUR VOUS ASSISTER",
    p: "La priorité de notre équipe est de vous proposer les meilleures offres, mais aussi de vous offrir la meilleure expérience d'achat, c'est pourquoi notre équipe de soutien est toujours heureuse de vous assister du Lundi au Dimanche de 9h00 à 19h.",
    helpCenterCard1: "Passation & suivi de commande",
    helpCenterCard2: "Annulation de commande",
    helpCenterCard3: "Retour & remboursement",
    helpCenterCard4: "Paiement & compte",
    question: "Avez-vous des difficultés avec votre commande ?",
    FAQTitle: "FAQ : CONSULTEZ LES QUESTIONS FRÉQUENTES",
    FAQDesc:
      "Insérez les questions concernant la livraison, le retour, les commandes, Gestion des commandes, Paramètres du compte et méthodes de paiement, Retours et remboursements, Politiques d'expédition, etc.",
    FAQInputPlaceholder: "Ecrivez votre question ici",
    callUsTitle: "APPELEZ-NOUS",
    callUsDesc:
      "Appelez-nous depuis tout le Maroc Du Lundi au Dimanche de 9h00 à 19h",
    slideOneBagde: "Remise sur la vente d'ouverture 50%",
    slideOneHeading: "Trouver les meilleurs produits artistiques et artisanals",
    slideOneText:
      "Lancement d'un nouveau modèle artistque et artisanal en ligne et livraison à domicile pratique et rapide.",
    sildeTwoBagde: "Livraison gratuite - commandes de plus de 1000 MAD",
    sildeTwoHeading: "Livraison gratuite sur commandes supérieures à",
    sildeTwoHeadingPrice: "1000 MAD",
    sildeTwoText:
      "Livraison gratuite uniquement pour les nouveaux clients, après l'application des promotions et des remises.",
    shopNowButton: "Achetez maintenan",
    mostVisitedCats: "Les catégories les plus visitées",
    buyNowButton: "Achetez maintenant",
    filterCategories: "Les catégories",
    filterPrice: "Prix",
    filterEvaluation: "Évaluation",
    productsFound: "Produits trouvés",
    display: "Afficher",
    sortByFeatured: "Trier par : En vedette",
    sortByPriceLower: "Prix : du plus bas au plus élevé",
    sortByPriceHigher: "Prix : du plus élevé au plus bas",
    sortByDate: "Date de sortie",
    onSale: "En solde",
    addToCard: "Ajouter",
    popularProducts: "Produits populaires",
    card1Title: "Soutenir une grande cause",
    card1TextPart1: "En achetant chez",
    card1TextPart2: "Entre Murs Galerie,",
    card1TextPart3:
      "vous soutenez vos fabricants locaux et vous contribuez à une cause plus importante",
    card2Title: "Meilleurs prix & offres",
    card2Text:
      "Des prix moins chers que votre supermarché local, d'excellentes offres de cashback pour couronner le tout. Obtenez les meilleurs prix et offres.",
    card3Title: "Large assortiment",
    card3Text:
      "Choisissez parmi plus de 5000 produits dans les catégories cuir, marquetrie, bois et autres.",
    card4Title: "Retours faciles",
    card4Text:
      "Pas satisfait d'un produit ? Retournez-le à la porte et obtenez un remboursement en quelques heures. Politique sans poser de questions.",
    card4Link: "politique.",
    myOrders: "Vos commandes",
    settings: "Paramètre",
    notifications: "Notification",
    orders: "Vos commandes",
    products: "Produit",
    orderId: "Commande",
    date: "Date",
    qty: "Articles",
    status: "Statut",
    price: "Montant",
    accountSettings: "Paramètre du compte",
    detailsTitle: "Détails du compte",
    name: "Nom",
    saveDetails: "Enregistrer les détails",
    newPass: "Nouveau mot de passe",
    currentPass: "Mot de passe actuel",
    resetPassText: "Vous ne vous souvenez pas de votre mot de passe actuel? ",
    resetPassLink: "Réinitialisez votre mot de passe.",
    savePassword: "Enregistrer le mot de passe",
    deleteAccountTitle: "Supprimer le compte",
    deleteAccQuestion: "Souhaitez-vous supprimer votre compte ?",
    deleteAccNote1: "Ce compte contient",
    deleteAccNote2:
      "La suppression de votre compte supprimera tous les détails de la commande qui lui sont associés.",
    deleteAccAction: "Je veux supprimer mon compte",
    changePassSuccess: "Votre mot de passe a été modifié.",
    changePassError:
      "Erreur lors du changement de votre mot de passe, veuillez réessayer!",
    updateProfileSuccess: "Votre profil a été mis à jour.",
    updateProfileError:
      "Erreur lors de la mise à jour de votre profil, veuillez réessayer !",
    deleteAccountSuccess: "Votre compte a été supprimé.",
    deleteAccountError:
      "Erreur lors de la suppression de votre compte, veuillez réessayer !",
    myAddresses: "Adresse",
    addNewModel: "ajouter une nouvelle adresse",
    modelTitle: "Nouvelle adresse de livraison",
    modelDesc:
      "Ajoutez une nouvelle adresse de livraison pour la livraison de votre commande.",
    firstAddress: "Adresse 1",
    secondAddress: "Adresse 2",
    businessName: "Nom de Ventreprise",
    setAsDefault: "Définir comme par défaut",
    saveAddress: "Enregistrer l'adresse",
    defaultAddress: "adresse par défaut",
    modify: "Modifier",
    delete: "supprimer",
    modifyModelTitle: "Modifier l'adresse",
    errorAdding:
      "Erreur lors de l'ajout de cette adresse, veuillez réessayer !",
    errorUpdating:
      "Erreur lors de la mise à jour de cette adresse, veuillez réessayer !",
    errorDeleting:
      "Erreur lors de la suppression de cette adresse, veuillez réessayer !",
    successAdding: "Votre adresse a été ajoutée avec succès.",
    successUpdating: "Votre adresse a été mise à jour avec succès.",
    successDeleting: "Votre adresse a été supprimée avec succès.",
    wishlist: "Votre liste de souhaits",
    errorOccurred: "Une erreur est survenue ! Veuillez réessayer plus tard",
    itemAdded: "L'article a été ajouté à votre liste de souhaits",
    itemRemoved: "L'article a été supprimé de votre liste de souhaits",
    allItemsRemoved:
      "Tous les articles ont été supprimés de votre liste de souhaits.",
    removeItemConf:
      "Êtes-vous sûre de vouloir supprimer cet article de votre liste de souhaits?",
    removeAllText: "Supprimer tous les articles",
    emptyListText: "Votre liste de souhaits est vide,",
    emptyListLink:
      "commencez à faire vos achats et ajoutez vos articles préférés à votre liste maintenant.",

    confirmRemovingItemTitle:
      "Confirmer la suppression de cet article de votre liste de souhaits",
    confirmRemovingAllTitle:
      "Confirmer la suppression de tous les articles de votre liste de souhaits",
    qtyUpdated: "La quantité de l'article a été mise à jour avec succès",
    removeConfirm: "Confirmation pour supprimer l'élément",
    removeConfMsg:
      "Êtes-vous sûr de vouloir supprimer cet article du panier d'achat ?",
    removeAll: "Vider le panier",
    selectAddress: "Sélectionner une adresse",
    selectPayment: "Méthode de paiement",
    COD: "Contre remboursement",
    addAddressText: "Vous n'avez pas encore ajouté d'adresses,",
    addAddressLink: "allez dans les paramètres de vos adresses pour ajouter.",
    productCode: "Code produit:",
    availability: "Disponibilité:",
    type: "Type:",
    shipping: "Expédition:",
    productDetailsTabTitle: "Détails du produit",
    informationTabTitle: "Information",
    reviewsTabTitle: "Avis",
    descriptionTab: "Description",
    subTitle: "Description de produit",
    unitTitle: "Unité",
    sellerTitle: "Vendeur",
    informationTab: "Détails",
    weight: "Poids",
    ingredient: "Type of ingredient",
    units: "Quantity of product packaging",
    recommendedItems: "Recommandé pour vous",
    notFountText: "La page que vous essayez d'accéder est introuvable",
    notFoundButton: "Redirection vers la page d'accueil",
    loadMore: "Charger plus",
    loadLess: "Charger moins",
    customerRatingsTitle: "Avis client",
    globalReviews: "Évaluations globales",
    outOf: "out of",
    customerRatingFooterHead: "Donnez votre avis sur ce produit",
    customerRatingFooterTail: " Partagez vos pensées avec d'autres clients.",
    reviews: "Avis",
    addYourReview: "Ajouter votre avis",
    noReviewsYet: "Aucun avis sur ce produit pour le moment.",
    addReviewSuccessMsg: "Merci pour votre avis, il a été ajouté avec succès.",
    addReviewErrorMsg:
      "Erreur lors de l'ajout de votre avis, veuillez réessayer.",
    pleaseSelectScore:
      "Veuillez sélectionner votre note pour soumettre votre avis.",
    outOfStock: "épuisé",
    dataIsEmpty: "Le nom, l'email et le téléphone ne peuvent pas être vides.",
    passIsEmpty:
      "Le mot de passe et la confirmation du mot de passe ne peuvent pas être vides.",
  },
};

export default fr;
