import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import { FaEye, FaEyeLowVision } from "react-icons/fa6";
import { LangContext } from "../../../contexts/LanguageProviderContext";
import messages from "../../../i18n/messages";

const InputPassword = ({ isRequired }: { isRequired?: boolean }) => {
  const [showPass, setShowPass] = useState(false);
  const { locale } = useContext(LangContext);
  const ShowPassIcon = !showPass ? FaEye : FaEyeLowVision;

  const handleShowPass = () => {
    setShowPass(!showPass);
  };
  return (
    <InputGroup>
      <Input
        name="password"
        placeholder={messages[locale].signInPassword}
        size="md"
        borderColor={"rgb(1,17,77)"}
        _hover={{ borderColor: "rgb(1,17,77)" }}
        borderWidth={1}
        mb={3}
        type={showPass ? "text" : "password"}
        isRequired={isRequired}
        // value={"12345678"}
      />
      {locale === "ar-ma" ? (
        <InputLeftElement
          color={"#5e6c75"}
          onClick={handleShowPass}
          cursor={"pointer"}
        >
          <ShowPassIcon />
        </InputLeftElement>
      ) : (
        <InputRightElement
          color={"#5e6c75"}
          onClick={handleShowPass}
          cursor={"pointer"}
        >
          <ShowPassIcon />
        </InputRightElement>
      )}
    </InputGroup>
  );
};

export default InputPassword;
