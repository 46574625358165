import { locales } from "../locales";

const ar = {
  [locales.arabic]: {
    hello: "مرحبا",
    appTitle: "بين الجدران جاليري",
    LocalSwitcherTitle: "أفضل العروض في السوق",
    france: "الفرنسية",
    arabic: "العربية",
    copyrights: "جميع حقوق النشر محفوظة",
    followUs: "تابعنا على",
    forCustomers: "لعملائنا",
    Payments: "المدفوعات",
    Shipping: "الشحن",
    productReturns: "المرتجعات",
    faq: "الأسئلة الشائعة",
    paymentAtCheckout: "الدفع عند الإستلام",
    MeetWithUs: "قابلنا",
    about: "من نحن",
    blog: "مدونتنا",
    helpCenter: "مركز المساعدة",
    ourValue: "قيمنا",
    SearchInput: "البحث عن المنتجات",
    drawerTitle: "عربة التسوق",
    emptyCartText: "عربة التسوق فارغة،",
    emptyCartLink: "ابدأ تجربتك التسوقية الأفضل الآن.",
    currency: "درهم",
    remove: "حذف",
    removeItemConfirm: "تأكيد حذف المنتج",
    removeItemConfMsg: "هل أنت متأكد أنك تريد حذف هذا المنتج من عربة التسوق؟",
    yes: "نعم",
    no: "لا",
    removeAllItems: "إفراغ السلة",
    removeAllConf: "هل أنت متأكد من رغبتك في حذف جميع الأصناف من السلة؟",
    execute: "نفذ أمر الشراء",
    signInButton: "تسجيل الدخول",
    signInPassword: "كلمة المرور",
    email: "البريد الإلكتروني",
    forgotPassword: "نسيت كلمة السر؟",
    forgotPasswordLink: "إعادة تعيينها",
    dontHaveAcc: "ليس لديك حساب؟",
    registerLink: "سجل معنا",
    signInError:
      "البريد الإلكتروني أو الكلمة المرور غير صحيحة، يرجى مراجعة بيانات الاعتماد الخاصة بك والمحاولة مرة أخرى.",
    menuCategores: "الفئات",
    allCategories: "كل الفئات",
    menuMainPage: "الصفحة الرئيسية",
    menuMoreCategories: "المزيد من الفئات",
    menuAccount: "الحساب",
    menu4Item1: "تسجيل الدخول",
    menu4Item2: "إنشاء حساب مستخدم جديد",
    menu4Item3: "إستعادة كلمة المرور",
    menu4Item4: "الحساب الشخصي",
    menu4Item4Nested1: "الطلبات",
    menu4Item4Nested2: "الإعدادات",
    menu4Item4Nested3: "العناوين",
    menu4Item4Nested4: "طريقة الدفع",
    menu4Item4Nested5: "الإشعارات",
    shoppingCart: "عربة التسوق",
    signOut: "تسجيل الخروج",
    menuHelp: "المساعدة",
    signInPage: "تسجيل الدخول للمنصة",
    signInDesc: "برجاء إدخال بريدك الإلكتروني للبدء",
    username: "إسم المستخدم",
    alreadyHaveAcc: "لديك حساب بالفعل؟",
    signInLink: "تسجيل دخول",
    signUpPage: "ابدأ بالتسوق",
    signUpDescription: "أدخل بريدك الإلكتروني للبدء.",
    firstName: "الإسم الأول",
    lastName: "الإسم الأخير",
    phone: "رقم الهاتف",
    address: "العنوان",
    city: "المدينة",
    postCode: "الرقم البريدي",
    password: "إدخل كلمة المرور",
    signUpButton: "سجل معنا",
    termsAndConditionText: "عند المتابعة ، يتم الموافقة على",
    termsOfServicesLink: "الشروط والأحكام",
    privacyPolicy: "سياسة الخصوصية",
    signUpError: "إسم المستخدم غير متوفر ، برجاء إدخال إسم مستخدم آخر.",
    signUpDone: "تم تسجيل حسابك ، أهلاً بك في Entre Murs Galerie.",
    resetPasswordPage: "نسيت كلمة المرور؟",
    resetPasswordDescription:
      "يرجى إدخال عنوان البريد الإلكتروني المرتبط بحسابك وسنرسل إليك عبر البريد الإلكتروني رابطًا لإعادة تعيين كلمة المرور الخاصة بك.",
    resetPasswordButton: "إعادة تعيين كلمة المرور",
    goBack: "العودة لتسجيل مستخدم جديد",
    helpCenterTitle: "مركز المساعدة :",
    helpCenterDescription: "نحن هنا لمساعدتك",
    p: "أولوية فريقنا هي تقديم أفضل العروض لكم، بالإضافة إلى توفير أفضل تجربة شراء ممكنة، لذلك فإن فريقنا الداعم دائماً مسرور بمساعدتكم من الاثنين إلى الأحد من الساعة 9:00 صباحاً حتى الساعة 7:00 مساءً.",
    helpCenterCard1: "تقديم الطلب وتتبعه",
    helpCenterCard2: "إلغاء الطلب",
    helpCenterCard3: "إرجاع واسترداد",
    helpCenterCard4: "الدفع والحساب",
    question: "هل تواجه أي صعوبات مع طلبك؟",
    FAQTitle: "الأسئلة الشائعة: تحقق من الأسئلة المتكررة",
    FAQDesc:
      "أدرج الأسئلة المتعلقة بالتوصيل، الإرجاع، الطلبات، إدارة الطلبات، إعدادات الحساب وطرق الدفع، الإرجاعات والاستردادات، سياسات الشحن، وما إلى ذلك.",
    FAQInputPlaceholder: "اكتب سؤالك هنا",
    callUsTitle: "Call us",
    callUsDesc:
      "اتصل بنا من أي مكان في المغرب من الاثنين إلى الأحد من الساعة 9:00 صباحًا حتى الساعة 7:00 مساءً.",
    slideOneBagde: "خصومات 50% بمناسبة الإفتتاح",
    slideOneHeading: "للعثور على أفضل المنتجات الفنية والحرفية",
    slideOneText:
      "إطلاق نموذج جديد فني وحرفي عبر الإنترنت مع خدمة التوصيل السريعة والمريحة إلى المنزل.",
    sildeTwoBagde: "توصيل مجاني - للطلبات بقيمة تزيد عن 1000 درهم مغربي",
    sildeTwoHeading: "توصيل مجاني للطلبات التي تزيد عن",
    sildeTwoHeadingPrice: "1000 درهم مغربي",
    sildeTwoText:
      "توفر الشحن المجاني فقط للعملاء الجدد بعد تطبيق العروض والتخفيضات.",
    shopNowButton: "اشتر الآن",
    mostVisitedCats: "أكثر الفئات زيارةً",
    buyNowButton: "اشتر الآن",
    filterCategories: "الفئات",
    filterPrice: "السعر",
    filterEvaluation: "التقييم",
    productsFound: "منتج معروض",
    display: "إظهار",
    sortByFeatured: "الترتيب حسب: مميز",
    sortByPriceLower: "السعر: من الأدنى إلى الأعلى",
    sortByPriceHigher: "السعر: من الأعلى إلى الأدنى",
    sortByDate: "تاريخ الإصدار",
    onSale: "تخفيض",
    addToCard: "إضافة",
    popularProducts: "أشهر المنجات",
    card1Title: "دعم قضية كبيرة",
    card1TextPart1: "بشراء من",
    card1TextPart2: "معرض Entre Murs,",
    card1TextPart3: "أنتم تدعمون صانعيكم المحليين وتساهمون في قضية أكبر",
    card2Title: "أفضل الأسعار والعروض",
    card2Text:
      "أسعار أقل، عروض نقدية ممتازة لإضافة اللمسة النهائية. احصل على أفضل الأسعار والعروض.",
    card3Title: "تشكيلة واسعة",
    card3Text:
      "اختر من بين أكثر من 5000 منتج في فئات الجلود، والزخرفة، والخشب، وغيرها.",
    card4Title: "إرجاع سهل",
    card4Text:
      "لست راضيًا عن منتج؟ قم بإعادته واحصل على استرداد في غضون ساعات قليلة. سياسة إرجاع بدون طرح أسئلة.",
    card4Link: "لمعلومات أكثر.",
    myOrders: "طلباتك",
    settings: "إعدادات الحساب",
    notifications: "إعدادات الإشعارات",
    orders: "طلباتك",
    products: "المنتج",
    orderId: "رقم الطلب",
    date: "التاريخ",
    qty: "الكمية",
    status: "الحالة",
    price: "المبلغ",
    accountSettings: "إعدادات الحساب",
    detailsTitle: "بيانات الحساب",
    name: "الإسم",
    saveDetails: "حفظ البيانات",
    newPass: "كلمة المرور الجديدة",
    currentPass: "كلمة المرور الحالية",
    resetPassText: "لا تتذكر كلمة مرورك الحالية؟",
    resetPassLink: "أعد تعيين كلمة المرور الخاصة بك.",
    savePassword: "حفظ كلمة المرور",
    deleteAccountTitle: "حذف الحساب",
    deleteAccQuestion: "هل تريد حقاً حذف حسابك؟",
    deleteAccNote1: "يحتوي هذا الحساب على ",
    deleteAccNote2: "سيؤدي حذف حسابك إلى حذف جميع تفاصيل الطلبات المرتبطة به.",
    deleteAccAction: "أريد حذف حسابي",
    changePassSuccess: "تم تغيير كلمة المرور بنجاح.",
    changePassError: "خطأ في تغيير كلمة المرور ، برجاء المحاولة مرة آخرى!",
    updateProfileSuccess: "تم تعديل بيانات حسابك بنجاح.",
    updateProfileError: "خطأ في تعديل بياناتك ، برجاء المحاولة مرة آخرى!",
    deleteAccountSuccess: "تم حذف حسابك.",
    deleteAccountError: "خطأ في حذف حسابك برجاء المحاولة مرة آخرى!",
    myAddresses: "العناوين",
    addNewModel: "إضافة عنوان جديد",
    modelTitle: "عنوان توصيل جديد",
    modelDesc: "إضافة عنوان توصيل جديد خاص بك يمكن إختياره لتوصيل طلباتك.",
    firstAddress: "العنوان 1",
    secondAddress: "العنوان 2",
    businessName: "إسم العمل",
    setAsDefault: "تحديد كعنوان أساسي",
    saveAddress: "حفظ العنوان",
    defaultAddress: "محدد كعنوان أساسي",
    modify: "تعديل",
    delete: "حذف",
    modifyModelTitle: "تعديل العنوان",
    errorAdding: "خطأ أثناء إضافة هذا العنوان، يرجى المحاولة مرة أخرى!",
    errorUpdating: "خطأ أثناء تعديل هذا العنوان، يرجى المحاولة مرة أخرى!",
    errorDeleting: "خطأ أثناء حذف هذا العنوان، يرجى المحاولة مرة أخرى!",
    successAdding: "تمت إضافة عنوانك بنجاح.",
    successUpdating: "تم تعديل عنوانك بنجاح.",
    successDeleting: "تم حذف عنوانك بنجاح.",
    wishlist: "قائمة أصنافك المفضلة",
    errorOccurred: "حدث خطأ! الرجاء المحاولة مرة أخرى لاحقاً",
    itemAdded: "تمت إضافة العنصر إلى قائمة المفضلة",
    itemRemoved: "تمت حذف العنصر من قائمة المفضلة",
    allItemsRemoved: "تمت حذف جميع العناصر من قائمة المفضلة.",
    removeItemConf:
      "هل أنت متأكد أنك تريد حذف هذا العنصر من قائمة أصنافك المفضلة؟",
    removeAllText: "حذف جميع المنتجات من قائمتك",
    emptyListText: "فائمة أصنافك المفضلة فارغة,",
    emptyListLink: "إبدأ بالتسوق وإضافة منتجاتك المفضلة لقائمتك الآن.",
    confirmRemovingItemTitle: "تأكيد حذف المنتج من قائمة أصنافك المفضلة",
    confirmRemovingAllTitle: "تأكيد حذف جميع الأصناف من فائمة أصنافك المفضلة",
    qtyUpdated: "تم تحديث كمية المنتج بنجاح",
    removeConfirm: "تأكيد حذف العنصر",
    removeConfMsg: "هل أنت متأكد أنك تريد حذف هذا المنتج من عربة التسوق؟",
    removeAll: "إفراغ السلة",
    selectAddress: "اختر عنوان",
    selectPayment: "طريقة الدفع",
    COD: "الدفع عند الإستلام",
    addAddressText: "لم تقم بإضافة أي عناوين بعد،",
    addAddressLink: "اذهب إلى إعدادات العناوين لإضافة عنوان.",
    productCode: "رمز المنتج:",
    availability: "الحالة:",
    type: "النوع:",
    shipping: "الشحن:",
    productDetailsTabTitle: "تفاصيل المنتج",
    informationTabTitle: "معلومات",
    reviewsTabTitle: "التقييمات",
    descriptionTab: "المواصفات",
    subTitle: "وصف المنتج",
    unitTitle: "الوحدة",
    sellerTitle: "البائع",
    informationTab: "التفاصيل",
    weight: "الوزن",
    ingredient: "نوع الخامات",
    units: "عدد الوحدات بالعبوة",
    recommendedItems: "نرشح لك",
    notFountText: "الصفحة التي تحاول الوصول إليها غير متوفره",
    notFoundButton: "إعادة توجيه إلى الصفحة الرئيسية",
    loadMore: "عرض المزيد",
    loadLess: "عرض الأقل",
    customerRatingsTitle: "تقييمات العملاء",
    globalReviews: "إجمالي التقييمات",
    outOf: "من",
    customerRatingFooterHead: "قيّم هذا المنتج",
    customerRatingFooterTail: " شارك أفكارك مع عملاء آخرين.",
    reviews: "التقييمات",
    addYourReview: "أضف تقييمك للمنتج",
    noReviewsYet: "لا يوجد تقييمات لهذا المنتج بعد",
    addReviewSuccessMsg: "شكراً لكتابة تقييمك ، تم إضافة التقييم بنجاح",
    addReviewErrorMsg: "خطأ في إضافة تقييمك ، برجاء إعادة المحاولة",
    pleaseSelectScore: "برجاء إختيار تقييمك للمنتج ليتم إضافة التعليق",
    outOfStock: "غير متوفر حالياً",
    dataIsEmpty: "الاسم والبريد الإلكتروني والهاتف لا يمكن أن تكون فارغة.",
    passIsEmpty: "كلمة المرور وتأكيد كلمة المرور لا يمكن أن تكون فارغة.",
  },
};

export default ar;
