import { jwtVerify, SignJWT } from "jose";
import { createContext, ReactNode, useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { postRequest } from "../utils/db";

export const AuthContext = createContext<any>({});

const AuthProviderContext = ({ children }: { children: ReactNode }) => {
  const [session, setSession] = useState<any>(null);
  const cookies = new Cookies();
  const key = new TextEncoder().encode("ajhasdjhfasudifuasd87687ashjdj");

  const fetchSession = async () => {
    const data = await getSession();
    if (data) setSession(data);
  };
  useEffect(() => {
    fetchSession();
  }, []);

  const encrypt = async (payload: any) => {
    const expires = new Date(Date.now() + 24 * 60 * 60 * 1000);
    return await new SignJWT(payload)
      .setProtectedHeader({ alg: "HS256" })
      .setIssuedAt()
      .setExpirationTime(expires)
      .sign(key);
  };

  const decrypt = async (input: string): Promise<any> => {
    try {
      const { payload } = await jwtVerify(input, key, {
        algorithms: ["HS256"],
      });
      return payload;
    } catch (error) {
      if (error instanceof Error && error.message.includes("exp")) {
        return null;
      }

      throw error;
    }
  };
  const signUp = async (formData: FormData) => {
    const user = {
      username: formData.get("username"),
      email: formData.get("email"),
      password: formData.get("password"),
      phone: formData.get("phone"),
    };
    const registeredUser = await postRequest("/api/clients", user);
    console.log("registeredUser", registeredUser);
    if (!registeredUser?.error) {
      const userLogin = {
        username: formData.get("email"),
        password: formData.get("password"),
      };
      const data = await postRequest("/api/clients/signin", userLogin);
      if (!data?.error) {
        const expires = new Date(Date.now() + 60 * 60 * 24 * 1000);
        setSession(data);
        const session = await encrypt({ data, expires });
        cookies.set("session", session, {
          expires,
          sameSite: "none",
          secure: true,
        });
      }
    }
    return registeredUser;
  };
  const signIn = async (formData: FormData) => {
    const user = {
      username: formData.get("email"),
      password: formData.get("password"),
    };

    const data = await postRequest("/api/clients/signin", user);
    if (!data?.error) {
      const expires = new Date(Date.now() + 60 * 60 * 24 * 1000);
      setSession(data);
      const session = await encrypt({ data, expires });
      cookies.set("session", session, {
        expires,
        sameSite: "none",
        secure: true,
      });
    }

    return data;
  };

  const signOut = async () => {
    cookies.remove("session", { sameSite: "none", secure: true });
    setSession(null);
  };

  const getSession = async () => {
    const session = cookies.get("session");
    if (session) {
      setSession(decrypt(session));
      return decrypt(session);
    } else return null;
  };
  return (
    <AuthContext.Provider
      value={{ signUp, signIn, signOut, getSession, session }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProviderContext;
